import React from 'react';
import { PageProps } from 'gatsby';
import { useDispatch } from 'react-redux';
import { useRouter } from 'apis/history';

import { NavigationMenuButton } from 'layouts/navigations/NavigationMenuButton';
import { LoginForm } from './sections/LoginForm';

const Login: React.FC<PageProps> = React.memo(({ location }) => {
  const { goToNotFound, goToQuiz } = useRouter();
  const dispatch = useDispatch();

  return (
    <>
      <NavigationMenuButton onClick={goToQuiz} />
      <LoginForm />
    </>
  );
});

export default Login;
