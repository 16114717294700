import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import {
  Container,
  ContentFrameSmall,
  GridWrapper,
  H1,
  H5,
  Input,
} from '@kilornd/modules-wrapper/es/components';
import {
  useQuery,
  xsmDesktop,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import {
  Image,
  PrimaryButton,
  SecondaryButton,
  SectionWrapper,
} from 'components';

import { neutral50, neutral500, neutral300 } from 'styles/colors';

export const LoginForm: React.FC = () => {
  const { isMobile } = useQuery();
  const [userInfo, setUserInfo] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (userInfo) {
      setErrorMsg('This Username does not exist');
    } else if (!userInfo && errorMsg) {
      setErrorMsg('');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInfo(e.target.value);

    if (!!userInfo && errorMsg) {
      setErrorMsg('');
    }
  };

  console.log(userInfo);

  return (
    <SectionWrapper>
      <Container>
        <ContentFrameSmall margin={isMobile ? '0' : '7rem auto 0 auto'}>
          <H1 margin="0 0 1rem">Members Area</H1>
          <H5 margin="0 0 1rem">LOGIN DETAILS</H5>
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Username or email"
              placeholderColor={neutral500}
              onChange={handleChange}
              errorMessage={errorMsg}
              min={2}
              max={230}
            />
            <Input
              type="password"
              placeholder="Password"
              placeholderColor={neutral500}
              min={8}
              max={30}
            />
            <PrimaryButton
              id="login_button"
              data-testid="login_button"
              margin="0 0 2.5rem"
            >
              Log in
            </PrimaryButton>
          </Form>
          <H5 margin="0 0 0.5rem">NOT A MEMBER YET?</H5>
          <Link to="/quiz">
            <SecondaryButton
              id="login__goToQuiz_button"
              data-testid="login__goToQuiz_button"
            >
              Take a quiz to start
            </SecondaryButton>
          </Link>
        </ContentFrameSmall>
      </Container>
    </SectionWrapper>
  );
};

const Form = styled(GridWrapper).attrs({
  as: 'form',
  gridGap: '1rem',
  columns: `${1}`,
})`
  margin: 0 0 2.5rem;
  border-bottom: 0.063rem solid ${neutral300};

  input {
    background-color: ${neutral50};
    border-radius: 0.5rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 28rem;
`;

const ImageLargeStyled = styled(Image)`
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 65rem;

  @media ${xsmDesktop} {
    max-width: 40rem;
  }
`;
